import axios from 'axios'
import store from '../store'
import { getToken, removeToken } from './auth'
import { DOMAIN } from './app'
import Vue from 'vue'
import { getApiHost } from '@/api'

const service = axios.create({
   baseURL: getApiHost() + '/index.php',
})

service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


service.interceptors.response.use(
  async (response) => {
    const res = response.data
    if (res.status !== 1) {

      if (res.status === 401) {
        Vue.ls && Vue.ls.remove('ACCESS_TOKEN');
        await store.dispatch('resetToken');
        location.href='#/login';
      }   
      if (res.status === 403) {
        location.href='#/member';
      }   
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
    //return response
  },
  error => {
  
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
