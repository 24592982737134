import Cookies from 'js-cookie'
import Vue from 'vue';
 

export function getCookie(key) {
  return Vue.ls && Vue.ls.get(key)
  //return Cookies.get(key)
}

export function setCookie(key,value) {
  return Vue.ls && Vue.ls.set(key, value)
  //return Cookies.set(key, value,{ expires: 7 })
}

export function removeCookie(key) {
  return Vue.ls && Vue.ls.remove(key)
  //return Cookies.remove(key)
}
