import Cookies from 'js-cookie'
import Vue from 'vue';

export function getRole() {
  const loginType = Vue.ls && Vue.ls.get('loginType');

  const isSeller = loginType == 1;
  const isDealer = loginType == 2;
  const isAdmin = loginType == 3;
  const isOwner = loginType == 7;
  const isAfSeller = loginType == 4;
  const isAfDealer = loginType == 5;
  const isAfDealerBoth = loginType == 6;
  const isAfOwner = loginType == 8;
  const isAfOwnerBoth = isAdmin || (loginType == 9);

  const cropId = Vue.ls && Vue.ls.get('cropId');
  const isVolvo = cropId == 12;

  //const brandId = Vue.ls && Vue.ls.get('brandId');
  const isAudi = ['27', '39'].indexOf(String(cropId)) >= 0;

  const adminId = Vue.ls && Vue.ls.get('adminId');
  // 超管-1，厂家1，经销店2
  const isSuper = adminId == -1;
  const isVendor = adminId == 1;
  const isDealers = adminId == 2;
  const isMaster = ['-1', '1'].indexOf(String(adminId)) >= 0;

  const userInfo =  Vue.ls && Vue.ls.get('USER_INFO') || {};
  const isReport = userInfo.isReport;
  
  return {
    isOwner,
    isAfOwnerBoth,
    isAfOwner,
    isAfDealerBoth,
    isAfSeller,
    isAfDealer,
    isSeller,
    isDealer,
    isDealers,
    isAdmin,
    isVolvo,
    isSuper,
    isVendor,
    isMaster,
    loginType,
    isReport,
    isAudi,
  }
}

export default getRole();
